import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { GiEarthAsiaOceania } from 'react-icons/gi';
import { LanguageNameMap, LanguageEnum } from 'src/locales';

const items: MenuProps['items'] = [
  {
    key: LanguageEnum.CN,
    label: LanguageNameMap[LanguageEnum.CN],
  },
  {
    key: LanguageEnum.EN,
    label: LanguageNameMap[LanguageEnum.EN],
  },
];

interface IProps {
  className?: string;
  size?: number;
}

export default function Language(props: IProps) {
  const { className, size = 24 } = props;
  const { i18n } = useTranslation();

  return (
    <Dropdown
      className={className}
      placement="top"
      arrow
      menu={{
        items,
        selectable: true,
        selectedKeys: [i18n.language],
        onSelect: (e) => {
          i18n.changeLanguage(e.key as LanguageEnum);
        },
      }}>
      <div style={{ width: size }}>
        <GiEarthAsiaOceania size={size} />
      </div>
    </Dropdown>
  );
}
