import { createContext, useRef, useEffect } from 'react';
import { EventEmitter } from 'events';

// 创建一个 Context 来共享 EventEmitter 实例
export const EventContext = createContext<EventEmitter | null>(null);

export const EventConsumer = EventContext.Consumer;

export const EventProvider = ({ children }: { children: React.ReactNode }) => {
  const eventEmitterRef = useRef(new EventEmitter());
  // 确保在组件卸载时清除所有事件监听器
  useEffect(() => {
    return () => {
      eventEmitterRef.current.removeAllListeners();
    };
  }, []);

  return <EventContext.Provider value={eventEmitterRef.current}>{children}</EventContext.Provider>;
};
