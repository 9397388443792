import { lazy } from 'react';
import { RouteObject } from 'src/interface/route';
import Layout from 'src/layout';

const Error403 = lazy(() => import('src/pages/error/403'));
const Error404 = lazy(() => import('src/pages/error/404'));
const Error500 = lazy(() => import('src/pages/error/500'));

const errorRoute: RouteObject[] = [
  {
    path: '/error',
    name: '错误页',
    data: {
      locale: 'error_page',
      notAuth: true,
    },
    element: <Layout />,
    children: [
      {
        path: '403',
        name: '403',
        data: {
          locale: 'error.no_permission',
          notAuth: true,
        },
        element: <Error403 />,
      },
      {
        path: '404',
        name: '404',
        data: {
          locale: 'error.not_found',
          notAuth: true,
        },
        element: <Error404 />,
      },
      {
        path: '500',
        name: '500',
        data: {
          locale: 'error.server_error',
          notAuth: true,
        },
        element: <Error500 />,
      },
    ],
  },
];

export default errorRoute;
