import { VisibleEnum } from './system';

export interface RouteData {
  visible?: boolean;
  locale?: string;
  flatMenu?: boolean;
  target?: TargetTypeEnum;
  notAuth?: boolean;
}

export interface RouteObject {
  // 组件路径用于异步加载
  element?: React.ReactNode;
  // 路由地址
  path: string;
  // 组件名称
  name?: string;
  // 组件icon
  icon?: React.ReactNode;
  // 自定义数据，存储菜单相关的数据
  data?: RouteData;
  // 子路由
  children?: RouteObject[];
  // 跳转链接
  redirect?: string;
}

export interface IMenuDataItem {
  menuId?: number;
  // 名称
  menuName?: string;
  // 路径
  path: string;
  // 父级ID
  parentId?: number;
  icon?: string | React.ReactNode;
  // 缓存菜单
  cache?: boolean;
  menuType?: MenuTypeEnum;
  // 显示菜单
  visible?: VisibleEnum;
  // 国际化
  locale?: string;
  // 用于标定选中的值，默认是 id
  key?: string;
  // 隐藏自身，将子菜单提升到与自己同级
  flatMenu?: boolean;
  // 处理外链以何种方式打开
  target?: TargetTypeEnum;
  // 子菜单
  children?: IMenuDataItem[];
  // 权限标识
  permission?: string;
}

export enum MenuTypeEnum {
  DIRE,
  MENU,
  BUTTON,
}

export enum TargetTypeEnum {
  IFRAME = 'iframe',
  SELF = '_self',
  BLANK = '_blank',
}
