/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';
import { RouteObject } from 'src/interface/route';
import Layout from 'src/layout';

const MessageCenter = lazy(() => import('src/pages/personal/message'));
const PersonalProfile = lazy(() => import('src/pages/personal/profile'));
const personalRoute: RouteObject[] = [
  {
    path: '/personal',
    element: <Layout />,
    name: '系统管理',
    children: [
      {
        path: 'message',
        name: '消息中心',
        element: <MessageCenter />,
      },
      {
        path: 'profile',
        name: '个人信息',
        element: <PersonalProfile />,
      },
    ],
  },
];

export default personalRoute;
