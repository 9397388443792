export const prefixCls = 'sloth';

export const HEAD_HEIGHT = 64;

export const TAG_HEIGHT = 32;

export const FOOTER_HEIGHT = 64;

export const COLLAPSED_WIDTH = 80;

export const PAGE_NOT_FOUND = '/error/404';

export const PAGE_NOT_PERMISSION = '/error/403';

export const PAGE_DEFAULT = '/';

export const PAGE_HOME = '/home';

export const PAGE_LOGIN = '/login';
