import { useEffect, useMemo } from 'react';
import { useUserInfo } from './useUserInfo';

interface IProps {
  url?: string;
}

export function useEventSource(props?: IProps) {
  const { url } = props || {};
  const { userInfo } = useUserInfo();

  const urlStr = useMemo(() => {
    return url ? url : userInfo?.userId ? `/api/notice/${userInfo?.userId}/stream` : null;
  }, [url, userInfo?.userId]);

  const eventSource = useMemo(() => {
    if (!urlStr) {
      return null;
    }
    const event = new EventSource(urlStr);
    return event;
  }, [url, urlStr]);

  useEffect(() => {
    return () => {
      eventSource?.close();
    };
  }, [eventSource]);

  return {
    eventSource,
  };
}
