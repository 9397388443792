import { useEffect } from 'react';
import { userStore } from 'src/stores/user';

export const useUserInfo = () => {
  const { userInfo, getUserInfo } = userStore((state) => ({
    userInfo: state.userInfo,
    getUserInfo: state.getUserInfo,
  }));

  useEffect(() => {
    if (!userInfo) {
      getUserInfo();
    }
  }, [userInfo]);

  return {
    userInfo,
  };
};
