import { Descriptions, Modal } from 'antd';
import type { DescriptionsProps, ModalProps } from 'antd';
import { useMemo } from 'react';
import parse from 'html-react-parser';
import { INoticeHistoryItem } from 'src/api/types/system';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import { markRead } from 'src/api/clients';
import { NoticeTypeLabel } from 'src/constants';
import { StatusEnum } from 'src/api/types/common';

export interface INoticeModalProps extends ModalProps {
  data: INoticeHistoryItem | null;
  onDelete?: (id: number) => void;
  onClose?: () => void;
}

export function NoticeModal(props: INoticeModalProps) {
  const { data, open, onDelete, onClose, ...extra } = props;

  useRequest(
    async () => {
      // 已读 或者没有记录ID
      if (!data?.id || data.status === StatusEnum.STOP) return null;
      await markRead([data.id]);
    },
    { refreshDeps: [data?.id] },
  );

  const descriptionInfo = useMemo(() => {
    if (!data) return [];
    const { noticeContent, noticeTitle, createTime, noticeStatusText, noticeType } = data;
    return [
      { key: '1', label: '名称', children: noticeTitle, span: 2 },
      { key: '2', label: '类型', children: noticeStatusText ?? NoticeTypeLabel[noticeType] },
      { key: '3', label: '发送时间', children: dayjs(createTime).format('YYYY-MM-DD HH:mm:ss') },
      {
        key: '4',
        label: '内容',
        children: <div className="rich-text-preview">{parse(noticeContent)}</div>,
        span: 2,
      },
    ] as DescriptionsProps['items'];
  }, [data]);

  const handleNotice = () => {
    if (data?.id) onDelete?.(data?.id);
  };

  const onCancel = () => {
    handleNotice();
    onClose?.();
  };

  return (
    <Modal
      open={open}
      {...extra}
      width={800}
      onCancel={onCancel}
      okText="我知道了"
      onOk={onCancel}
      closable={false}>
      <Descriptions bordered column={2} size="small" items={descriptionInfo} />
    </Modal>
  );
}
