import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LayoutTypeEnum, MenuModeEnum, ThemeEnum, PageModeEnum } from 'src/interface/theme';
import config from 'src/configs/setting.json';

export const _defaultConfig = {
  // 暗色风格
  dark: false,
  // 紧凑风格
  compack: false,
  pageTheme: {
    // 布局模式
    layoutMode: LayoutTypeEnum.Sider,
    // antd 主题配置
    theme: {
      colorPrimary: '#1677FF',
      colorSuccess: '#52C41A',
      colorWarning: '#FAAD14',
      colorError: '#FF4d4f',
      fontSize: 14,
      fontSizeMax: 32,
      borderRadius: 6,
      borderRadiusMax: 16,
      wireframe: false,
    },
    root: {
      showLogo: true,
    },
    menu: {
      menuDrag: false,
      menuWidth: 220,
      openKeys: false,
      mode: MenuModeEnum.Inline,
      showMenu: true,
      collapsed: false,
      showCollapsed: true,
      theme: ThemeEnum.Light,
      fixedFooter: true,
      pageMode: PageModeEnum.Normal,
      happyMode: false,
    },
    header: {
      fixed: false,
      show: true,
    },
  },
};

export const initStates = Object.assign({}, _defaultConfig, config);
export type AntdTheme = typeof initStates.pageTheme.theme;
export type MenuTheme = typeof initStates.pageTheme.menu;
export type HeaderTheme = typeof initStates.pageTheme.header;

interface IThemeStore {
  dark: boolean;
  compack: boolean;
  theme: typeof initStates.pageTheme;
  // antdTheme: AntdTheme;
  setTheme?: (theme: Partial<AntdTheme>) => void;
  setDarkMode?: (dark: boolean) => void;
  setPageCompack?: (compack: boolean) => void;
  setLayoutMode?: (layoutMode: LayoutTypeEnum) => void;
  setMenuConfig?: (menu: Partial<MenuTheme>) => void;
  setHeaderConfig?: (header: Partial<HeaderTheme>) => void;
  setAntdTheme?: (theme: Partial<AntdTheme>) => void;
  resetTheme?: () => void;
  changeCollapsed?: (collapsed: boolean) => void;
}

export const themeStore = create<IThemeStore>()(
  persist(
    (set, get) => ({
      theme: initStates.pageTheme,
      compack: initStates.compack,
      dark: initStates.dark,
      // antdTheme: initStates.pageTheme?.theme,
      setTheme: (t: Partial<AntdTheme>) => {
        const theme = get().theme;
        set({ theme: { ...theme, theme: { ...theme.theme, ...t } } });
      },
      setDarkMode: (dark: boolean) => set({ dark }),
      setPageCompack: (compack: boolean) => set({ compack }),
      setLayoutMode: (layoutMode: LayoutTypeEnum) => {
        const baseTheme = get().theme;
        set({ theme: { ...baseTheme, layoutMode } });
      },
      setMenuConfig: (menu: Partial<MenuTheme>) => {
        const baseTheme = get().theme;
        set({ theme: { ...baseTheme, menu: { ...baseTheme.menu, ...menu } } });
      },
      setHeaderConfig: (header: Partial<HeaderTheme>) => {
        const baseTheme = get().theme;
        set({ theme: { ...baseTheme, header: { ...baseTheme.header, ...header } } });
      },
      resetTheme: () => {
        set({
          theme: initStates.pageTheme,
          compack: initStates.compack,
          dark: initStates.dark,
        });
      },
      changeCollapsed: (collapsed: boolean) => {
        const baseTheme = get().theme;
        set({
          theme: { ...baseTheme, menu: { ...baseTheme.menu, collapsed } },
        });
      },
    }),
    {
      name: 'LAVA_THEME', // 持久化存储的键名
      partialize: (state) => ({
        dark: state.dark,
        compack: state.compack,
        theme: state.theme,
      }), // 仅持久化指定的状态
    },
  ),
);
