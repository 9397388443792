import { Suspense } from 'react';
import { Spin } from 'antd';
import { useOutlet, useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

export default function RouteElement() {
  const element = useOutlet();
  const location = useLocation();

  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-full w-full">
          <Spin spinning={true} size="large" />
        </div>
      }>
      <SwitchTransition>
        <CSSTransition
          unmountOnExit={true}
          key={location.pathname}
          classNames={{
            enter: 'animate__animated animate__bounceInLeft',
            exit: 'animate__animated animate__bounceOutRight',
          }}
          timeout={{ enter: 300, exit: 300 }}>
          {element}
        </CSSTransition>
      </SwitchTransition>
    </Suspense>
  );
}
