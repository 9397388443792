import request from 'src/api/http';
import { IDistrictItem, IGetDistrictsParams } from '../types/tools';

export const getDistrictList = (params?: IGetDistrictsParams) => {
  return request.get<IDistrictItem[]>('/amap/getLocation', { params });
};

export const uploadImage = (data: FormData) => {
  return request.post<string>('/upload/upload', {
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
