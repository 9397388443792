import { lazy } from 'react';
import { RouteObject } from 'src/interface/route';

const Login = lazy(() => import('src/pages/login'));

const basicRouteer: RouteObject[] = [
  {
    path: '/login',
    name: '登录页',
    data: {
      notAuth: true,
    },
    element: <Login />,
  },
];

export default basicRouteer;
