import { useContext } from 'react';
import { EventContext } from 'src/context/EventContext';

export const useEventEmitter = () => {
  const eventEmitter = useContext(EventContext);
  if (!eventEmitter) {
    throw new Error('useEventEmitter must be used within an EventProvider');
  }
  return eventEmitter;
};
