import { useEffect } from 'react';
import { useEventEmitter } from './useEventEmitter';

interface IEventListenerProps {
  name: string;
  callback: (...args: any[]) => void;
}

export const useListener = (props: IEventListenerProps) => {
  const { name, callback } = props;
  const eventEmitter = useEventEmitter();

  useEffect(() => {
    if (callback) {
      eventEmitter.on(name, callback);

      return () => {
        eventEmitter.removeListener(name, callback);
      };
    }
  }, [eventEmitter, name, callback]);
};
