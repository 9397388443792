import { Popover, Badge, Segmented, Empty, Typography, Button, Divider, message } from 'antd';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBoolean, useRequest } from 'ahooks';
import { AiOutlineBell } from 'react-icons/ai';
import dayjs from 'dayjs';
import { useEventSource } from 'src/hooks/useEventSource';
import { getNoticeHistory, markRead } from 'src/api/clients';
import { INoticeHistoryItem } from 'src/api/types/system';
import { AiOutlineNotification, AiOutlineSound } from 'react-icons/ai';
import { NoticeTypeEnum, StatusEnum } from 'src/api/types/common';
import { stripHtmlTags } from 'src/utils/tools';
import { tabStore } from 'src/stores/tabs';
import { NoticeModal } from 'src/components/NoticeModal';
import { NOTICE_MESSAGE } from 'src/constants/const';
import { useListener } from 'src/hooks/useListener';

interface IProps {
  className: string;
}

const { Paragraph } = Typography;

export default function Notice(props: IProps) {
  const { className } = props;
  const navigate = useNavigate();
  const { addTabs } = tabStore((state) => ({
    addTabs: state.addTabs,
  }));
  const [active, setActive] = useState<NoticeTypeEnum>(NoticeTypeEnum.NOTICE);
  const { eventSource } = useEventSource();
  const [visible, { setTrue, setFalse }] = useBoolean(false);
  const [open, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [loading, { setTrue: setShow, setFalse: setHide }] = useBoolean(false);
  const [noticeList, setNoticeList] = useState<INoticeHistoryItem[]>([]);
  const [noticeItem, setNoticeItem] = useState<INoticeHistoryItem | null>(null);

  useRequest(async () => {
    const res = await getNoticeHistory({ status: StatusEnum.NORMAL });
    setNoticeList(res);
    return res;
  });

  const handleNotice = useCallback(
    (id: number) => {
      setNoticeList((list) => list.filter((item) => item.id !== id));
    },
    [setNoticeList],
  );

  useListener({
    name: NOTICE_MESSAGE,
    callback: (id: number) => {
      handleNotice(id);
    },
  });

  const tabsList = useMemo(
    () => [
      {
        label: '通知',
        icon: <AiOutlineSound className="text-base" />,
        value: NoticeTypeEnum.NOTICE,
      },
      {
        label: '公告',
        icon: <AiOutlineNotification className="text-base" />,
        value: NoticeTypeEnum.ANNOUNCEMENT,
      },
    ],
    [],
  );

  const onOpenChange = (e: boolean) => {
    if (!e) {
      setFalse();
    }
  };

  const markReadAll = async () => {
    if (!noticeList?.length) return message.error('暂无消息');
    const ids = noticeList?.map((item) => item.id);
    setShow();
    try {
      await markRead(ids);
      setNoticeList([]);
    } finally {
      setHide();
    }
  };

  const goMessageAll = async () => {
    setFalse();
    addTabs({
      path: '/personal/message',
      name: '消息中心',
    });
    navigate('/personal/message');
  };

  useEffect(() => {
    if (eventSource) {
      eventSource.onopen = () => {
        console.log(
          '%cSSE 连接成功！',
          'color: white; background-color: green; padding: 4px 8px; font-size: 12px; font-weight: bold; border-radius: 5px;',
        );
      };
      eventSource.onerror = (error) => {
        console.log(
          '%cSSE 连接失败！',
          'color: white; background-color: red; padding: 4px 8px; font-size: 12px; font-weight: bold; border-radius: 5px;',
        );
        console.log('错误信息：', error);
      };
      eventSource.addEventListener('message', (e) => {
        setNoticeList((prev) => {
          const newData = JSON.parse(e.data);
          return [{ ...newData, id: Number(newData.id) }, ...prev];
        });
      });
    }
  }, [eventSource]);

  const renderMessage = useMemo(() => {
    const list = noticeList?.filter((item) => item.noticeType === NoticeTypeEnum.NOTICE);
    if (!list?.length) return <Empty description="暂无消息" className="py-8" />;
    return list?.map((item) => (
      <div key={item.id} className="p-2">
        <div className="flex justify-between items-center text-sm font-semibold py-1 text-gray-700">
          <Badge
            status="error"
            onClick={() => {
              setNoticeItem(item);
              setFalse();
              showModal();
            }}
            text={
              <span className="hover:text-blue-400 cursor-pointer">{item.noticeTitle}</span>
            }></Badge>
          <div className="shrink-0 font-normal text-sm">
            {item.createTime ? dayjs(item.createTime).format('YYYY-MM-DD') : null}
          </div>
        </div>
        <Paragraph className="!mb-0 w-[300px] max-w-[300px]" ellipsis={{ rows: 2 }}>
          {stripHtmlTags(item.noticeContent)}
        </Paragraph>
      </div>
    ));
  }, [noticeList?.length]);

  const renderNotice = useMemo(() => {
    const list = noticeList?.filter((item) => item.noticeType === NoticeTypeEnum.ANNOUNCEMENT);
    if (!list?.length) return <Empty description="暂无公告" className="py-8" />;
    return list?.map((item) => (
      <div key={item.id} className="p-2">
        <div className="flex justify-between items-center text-sm font-semibold py-1 text-gray-700">
          <Badge
            status="error"
            onClick={() => {
              setNoticeItem(item);
              setFalse();
              showModal();
            }}
            text={
              <span className="hover:text-blue-400 cursor-pointer">{item.noticeTitle}</span>
            }></Badge>
          <div className="shrink-0 font-normal text-sm">
            {item.createTime ? dayjs(item.createTime).format('YYYY-MM-DD') : null}
          </div>
        </div>
        <Paragraph className="!mb-0" ellipsis={{ rows: 2 }}>
          {stripHtmlTags(item.noticeContent)}
        </Paragraph>
      </div>
    ));
  }, [noticeList?.length]);

  return (
    <>
      <Popover
        open={visible}
        placement="bottom"
        trigger={['click', 'contextMenu']}
        onOpenChange={onOpenChange}
        content={
          <div className="w-[300px] h-[420px] max-h-[420px] overflow-hidden flex flex-col justify-start items-center">
            <Segmented
              className="w-full"
              value={active}
              onChange={setActive}
              options={tabsList?.map((item) => ({
                label: (
                  <div className="flex justify-center items-center gap-1">
                    {item.icon}
                    {item.label}
                  </div>
                ),
                value: item.value,
                className: 'w-1/2',
              }))}
            />
            <div className="h-[360px] overflow-y-auto overflow-x-hidden">
              {active === NoticeTypeEnum.NOTICE
                ? renderMessage
                : active === NoticeTypeEnum.ANNOUNCEMENT
                  ? renderNotice
                  : null}
            </div>
            <div className="w-full flex items-center">
              <Button type="link" className="w-1/2" loading={loading} onClick={markReadAll}>
                全部已读
              </Button>
              <Divider type="vertical" className="h-[80%]" />
              <Button type="link" className="w-1/2" onClick={goMessageAll}>
                查看全部
              </Button>
            </div>
          </div>
        }>
        <div className={clsx(className, 'relative')} onClick={setTrue}>
          <Badge size="small" count={noticeList?.length}>
            <AiOutlineBell size={20} />
          </Badge>
        </div>
      </Popover>
      <NoticeModal
        title={'消息详情'}
        data={noticeItem}
        onClose={() => {
          hideModal();
          setTrue();
        }}
        onDelete={handleNotice}
        open={open}
      />
    </>
  );
}
